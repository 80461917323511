<template>
  <v-card class="kit-summary camera-details__content">
    <ERow no-gutters class="e-pt-4">
      <div
        v-resize-observer="refreshLines"
        class="e-flex e-w-full e-justify-center"
      >
        <KitDetails
          ref="kitDetails"
          class="e-m-6"
          :grafana-metrics="grafanaMetrics"
          :ex-nvr-metrics="exNvrMetrics"
        />

        <div ref="kitLineAnchor" class="kit-summary__line-anchor"></div>
      </div>

      <ECol
        cols="12"
        class="kit-summary__cameras-section e-flex e-gap-3 px-1 e-justify-center e-w-full e-mt-8"
      >
        <div
          ref="camerasContainer"
          v-resize-observer="refreshLines"
          class="kit-summary__cameras-container e-flex e-gap-3 e-justify-center"
          :class="{
            'e-w-full e-flex-col e-items-center position-relative':
              $vuetify.breakpoint.smAndDown,
            ' e-items-baseline': !$vuetify.breakpoint.smAndDown,
          }"
        >
          <KitCamera
            v-for="camera in kitSummaryStore.cameras"
            :key="camera.exid"
            :ref="'camera-' + camera.exid"
            :camera="camera"
            class="e-m-4"
          ></KitCamera>
        </div>

        <KitCameraPlaceholder
          ref="camera-placeholder"
          class="e-m-4 e-mt-6"
          width="150px"
          height="120px"
        />
      </ECol>

      <div class="e-px-4 e-flex e-justify-center e-w-full">
        <div class="kit-metrics e-flex e-w-full position-relative e-flex-wrap">
          <template v-for="(metrics, chartType) in metricsByChartType">
            <template v-if="chartType === GrafanaChartType.Table">
              <div
                v-for="(metric, metricName) in metrics"
                :key="metricName"
                class="e-w-full"
              >
                <KitMetric
                  :metric="metric"
                  :metric-id="metricName"
                  :kit="kitSummaryStore.kit"
                  class="e-m-4"
                />
              </div>
            </template>
            <template v-else-if="chartType !== GrafanaChartType.Gauge">
              <KitMetric
                v-for="(metric, metricName) in metrics"
                :key="metricName"
                :metric="metric"
                :metric-id="metricName"
                :kit="kitSummaryStore.kit"
                class="e-m-4"
              />
            </template>
          </template>
        </div>
      </div>
    </ERow>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue"
import { mapStores } from "pinia"
import { useKitSummaryStore } from "@/stores/kitSummary"
import KitDetails from "@/components/kits/KitDetails"
import { EvercamLabsApi } from "@evercam/shared/api/evercamLabsApi"
import KitCamera from "@/components/kits/KitCamera.vue"
import KitMetric from "@/components/kits/KitMetric.vue"
import {
  CameraStatus,
  ExNvrMetric,
  ExNvrMetricId,
  GrafanaChartType,
  GrafanaMetricId,
  GrafanaMetricResponse,
} from "@evercam/shared/types"
import KitCameraPlaceholder from "@/components/kits/KitCameraPlaceholder.vue"

export default Vue.extend({
  name: "KitSummaryDialog",
  components: {
    KitCameraPlaceholder,
    KitCamera,
    KitMetric,
    KitDetails,
  },
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      metricsByChartType: {},
      lines: [],
      camerasContainerRef: null as HTMLElement | null,
      kitDetailsRef: null as HTMLElement | null,
    }
  },
  computed: {
    ...mapStores(useKitSummaryStore),
    grafanaMetrics(): GrafanaMetricResponse[] {
      return this.metricsByChartType[GrafanaChartType.Gauge]
    },
    exNvrMetrics(): Record<ExNvrMetricId, ExNvrMetric> | null {
      if (!this.kitSummaryStore.exNvrSystemStatus) {
        return null
      }

      const { cpu, memory } = this.kitSummaryStore.exNvrSystemStatus
      const { load, numCores } = cpu
      const cpuUsage =
        (load.reduce((a, b) => a + b, 0) / load.length / numCores) * 100

      const { totalSwap, freeSwap, systemTotalMemory, availableMemory } = memory
      const ramUsed =
        ((systemTotalMemory - availableMemory) / systemTotalMemory) * 100
      const swapUsed = ((totalSwap - freeSwap) / totalSwap) * 100

      return {
        [ExNvrMetricId.CpuUsage]: {
          type: GrafanaChartType.Gauge,
          value: cpuUsage,
        },
        [ExNvrMetricId.RamUsage]: {
          type: GrafanaChartType.Gauge,
          value: ramUsed,
        },
        [ExNvrMetricId.SwapUsage]: {
          type: GrafanaChartType.Gauge,
          value: swapUsed,
        },
      }
    },
    GrafanaChartType(): typeof GrafanaChartType {
      return GrafanaChartType
    },
  },
  async mounted() {
    this.camerasContainerRef = this.$refs.camerasContainer as HTMLElement
    this.kitDetailsRef = this.$refs.kitDetails?.$el as HTMLElement
    this.initConnectionLines()
    await this.fetchGrafanaMetrics()
  },
  beforeDestroy() {
    this.clearConnectionLines()
  },
  methods: {
    async fetchGrafanaMetrics() {
      const metrics = await EvercamLabsApi.kits.getMetrics(
        this.kitSummaryStore.kit.serial,
        [
          GrafanaMetricId.LocalStorage,
          GrafanaMetricId.RouterCpuLoad,
          GrafanaMetricId.SbcRamUsed,
          GrafanaMetricId.SbcRootFsUsed,
          GrafanaMetricId.RouterTemperature,
          GrafanaMetricId.SbcCpuStats,
          GrafanaMetricId.SbcRamStats,
          GrafanaMetricId.SbcTemperatureStats,
          GrafanaMetricId.StorageSpaceUsed,
          GrafanaMetricId.SbcNetworkStats,
          GrafanaMetricId.RouterSINR,
        ]
      )

      this.metricsByChartType = Object.entries(metrics).reduce(
        (acc, [metricName, metric]) => {
          if (!metric?.type) {
            return acc
          }

          return {
            ...acc,
            [metric.type]: {
              ...(acc[metric.type] || {}),
              [metricName]: metric,
            },
          }
        },
        {} as Record<
          GrafanaChartType,
          Array<Record<GrafanaMetricId, GrafanaMetricResponse>>
        >
      )
    },
    initConnectionLines() {
      this.drawConnectionLines()
      this.$addEventListener(
        "scroll",
        this.repositionConnectionLines,
        this.$globalRefs.mainContent
      )
    },
    repositionConnectionLines() {
      try {
        this.lines.forEach((line) =>
          requestAnimationFrame(() => line.position())
        )
      } catch (e) {
        console.log("<KitSummary />: Failed to reposition leader-lines", e)
      }
    },
    getKitLineAnchorStyle() {
      const x =
        this.camerasContainerRef.offsetLeft +
        this.camerasContainerRef.offsetWidth / 2
      const y = this.kitDetailsRef.offsetHeight + this.kitDetailsRef.offsetTop

      return {
        left: `${x}px`,
        top: `${y}px`,
      }
    },
    drawConnectionLines() {
      if (!this.camerasContainerRef || !this.kitDetailsRef || !this.$el) {
        return
      }

      if (!window.LeaderLine) {
        this.$setTimeout(this.drawConnectionLines, 100)

        return
      }

      const kitRef = this.$refs.kitLineAnchor
      Object.assign(kitRef.style, { ...this.getKitLineAnchorStyle() })

      this.kitSummaryStore.cameras.forEach((camera) => {
        const isOnline = camera.status === CameraStatus.Online
        const color = isOnline ? "#51b031" : "rgb(229, 231, 235)"
        const animation = isOnline ? { duration: 750 } : false
        const ref = this.$refs["camera-" + camera.exid]
        const line = new window.LeaderLine(ref?.[0]?.$el || ref?.$el, kitRef, {
          size: 2,
          startSocket: this.$vuetify.breakpoint.smAndDown ? "right" : "top",
          endSocket: "bottom",
          startPlug: "disc",
          startPlugSize: 1.5,
          endPlug: "disc",
          endPlugSize: 1.5,
          color,
          dash: { animation },
        })
        this.lines.push(line)
      })
    },
    closeDialog() {
      if (this.inline) {
        this.$router.go(-1)
      } else {
        this.$emit("close-dialog")
      }
    },
    clearConnectionLines() {
      try {
        this.lines.forEach((l) => l.remove())
        this.lines = []
      } catch (e) {
        console.log("<KitSummary />: Failed to clear leader lines", e)
      }
    },
    refreshLines() {
      this.$setTimeout(() => {
        try {
          this.clearConnectionLines()
          this.drawConnectionLines()
        } catch (e) {
          this.refreshLines()
        }
      }, 100)
    },
  },
})
</script>

<style lang="scss">
$text-color: rgb(75, 85, 99);

.kit-summary {
  min-height: 100vh;

  &__title {
    &,
    i {
      color: $text-color !important;
    }
  }

  .icon-size {
    font-size: 24px !important;
  }

  &__cameras-section {
    @media (max-width: 960px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .e-card {
    .summary-element {
      min-height: 20px;
      &__label {
        &,
        &.caption {
          letter-spacing: 0 !important;
          font-weight: 500 !important;
          color: $text-color;
        }
      }
      &__content {
        color: $text-color;
        letter-spacing: 0 !important;
        white-space: normal;
        .v-btn--icon {
          height: 24px;
        }
      }
    }
  }

  &__line-anchor {
    position: absolute;
    border: 1px solid red;
    height: 1px;
    width: 1px;
    @media (max-width: 960px) {
      right: 24px !important;
      left: initial !important;
      transform: translateY(-12px);
    }
  }

  @media (max-width: 960px) {
    .summary-element a {
      max-width: 85% !important;
      white-space: pre-line;
    }
  }
  .e-card {
    .v-chip {
      &.v-size--x-small {
        border-radius: 4px;
        font-size: 10px;
        height: 15px;
        padding: 0 7px;
        .v-chip__content {
          font-weight: bold;
        }
      }
    }
  }
}
</style>
