<template>
  <ECard
    title="Router"
    :image="imageUrl"
    blur-background
    :image-translation="20"
    :image-size="70"
    :image-padding="10"
    v-bind="$attrs"
  >
    <div v-if="hasRouter">
      <!-- SERIAL NUMBER -->
      <SummaryElement
        :value="kitSummaryStore.router.serialNumber"
        label="Serial Number"
        is-id
        read-only
        :is-copyable="hasRouter"
        :has-default-save-callback="false"
        @save="saveRouter"
      >
        <template #value-text>
          <span
            class="primary--text cursor-pointer"
            @click="routerStore.openDialog(kitSummaryStore.router)"
          >
            {{ kitSummaryStore.router.serialNumber }}
          </span>
        </template>
      </SummaryElement>

      <!-- ROUTER TYPE -->
      <SummaryElement
        v-model="routerType"
        :read-only="!hasRouter"
        :has-default-save-callback="false"
        @save="saveRouter"
      />

      <!-- POWER SCHEDULE -->
      <SummaryElement
        v-model="powerSchedule"
        :read-only="!hasRouter"
        :has-default-save-callback="false"
        @save="saveRouter"
      />

      <!-- VPN SERVER -->
      <SummaryElement
        v-model="vpnServer"
        :read-only="!hasRouter"
        :has-default-save-callback="false"
        @save="saveRouter"
      />

      <!-- VPN SERVER Port -->
      <SummaryElement
        v-model="httpPort"
        :read-only="!hasRouter"
        :has-default-save-callback="false"
        @save="saveRouter"
      >
        <template #append>
          <v-tooltip v-if="hasRouter" bottom content-class="d-inline-block">
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                x-small
                v-bind="attrs"
                target="_blank"
                class="ml-1 pb-05"
                :href="getVpnServerUrl"
                v-on="on"
                @click.stop="() => {}"
              >
                <v-icon small> fa-external-link-alt </v-icon>
              </v-btn>
            </template>
            <span>Open In A New Window </span>
          </v-tooltip>
        </template>
      </SummaryElement>

      <!-- NETBIRD URL -->
      <SummaryElement
        :value="kitSummaryStore.router.netbirdUrl"
        label="Netbird URL"
        read-only
        :is-copyable="hasRouter"
      >
        <template #value-text>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <ETruncatedDiv :width="200" v-bind="attrs" v-on="on">
                <a target="_blank" :href="kitSummaryStore.router.netbirdUrl">
                  <span>{{ kitSummaryStore.router.netbirdUrl }}</span>
                </a>
              </ETruncatedDiv>
            </template>
            <span>{{ kitSummaryStore.router.netbirdUrl }}</span>
          </v-tooltip>
        </template>
      </SummaryElement>

      <!-- USER ID -->
      <SummaryElement
        v-model="routerUserId"
        :read-only="!hasRouter"
        :has-default-save-callback="false"
        @save="saveRouter"
      />

      <!-- PASSWORD -->
      <SummaryElement
        v-model="routerPassword"
        :has-default-save-callback="false"
        :is-password="hasRouter"
        :read-only="!hasRouter"
        :is-copyable="hasRouter"
        @save="saveRouter"
      />

      <!-- SIMs -->
      <SummaryElement read-only label="SIMs">
        <div
          class="d-flex justify-space-between w-100 cursor-pointer"
          @mouseenter="isHovered = !isHovered"
          @mouseleave="isHovered = !isHovered"
          @click="clickEditSim"
        >
          <div>
            <SimsColumn
              v-show="!editSims"
              :item="kitSummaryStore.router"
              @open-sms-history-dialog="openSmsDialog"
            />
            <Autocomplete
              v-show="editSims"
              ref="simsSelect"
              v-model="sims"
              item-value="id"
              item-text="number"
              multiple
              chips
              small-chips
              deletable-chips
              :outlined="false"
              :list-items="sims"
              :auto-select="true"
              resource="sims"
              @focusout="saveSims"
            />
          </div>
          <div>
            <v-icon v-if="isHovered && !editSims" calss="text-right" small>
              fas fa-pen
            </v-icon>
          </div>
        </div>
      </SummaryElement>
      <SmsHistoryDialog
        v-model="showSmsHistoryDialog"
        :sim="selectedSim?.number"
        :edited-sim="selectedSim"
        :show-header="false"
      />
    </div>

    <div v-else>
      <!-- ADD ROUTER -->
      <div class="subtitle-2">
        <v-btn
          v-if="!hasRouter"
          color="primary"
          small
          plain
          class="mr-1"
          @click="addRouter"
        >
          <v-icon class="mr-2">fa-plus </v-icon> Add Router
        </v-btn>

        <AddRouterDialog
          v-model="addRouterDialog"
          :router-types="routerTypes"
        />
      </div>

      <!-- LINK EXISTING ROUTER -->
      <div v-if="!hasRouter && suggestedRouter" class="subtitle-2">
        <v-btn
          color="primary"
          small
          plain
          class="mr-1"
          @click="showSuggestedRouterDialog = true"
        >
          <v-icon class="mr-2">fa-plus </v-icon> Link existing router
        </v-btn>

        <v-dialog v-model="showSuggestedRouterDialog" width="450">
          <v-card class="suggested-router-dialog">
            <v-card-title> Link existing router </v-card-title>
            <v-card-text class="pb-0">
              This will link
              <strong> Router: {{ suggestedRouter.serialNumber }} </strong>
              to
              <strong> Kit: {{ kitSummaryStore.kit.serial }} </strong>
              <CameraSummaryRouter
                class="pt-4"
                :camera="cameraWithExistingRouter"
                :router="suggestedRouter"
              />
            </v-card-text>
            <v-card-actions class="pa-4">
              <v-spacer />
              <v-btn
                class="px-6"
                depressed
                @click="showSuggestedRouterDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                class="px-5"
                color="primary"
                depressed
                :loading="isLinkingRouter"
                @click="linkSuggestedRouter"
              >
                Link
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </ECard>
</template>

<script lang="ts">
import SummaryElement from "@/components/cameraTabs/summary/SummaryElement"
import Vue from "vue"
import { AdminApi } from "@evercam/shared/api/adminApi"
import AddRouterDialog from "@/components/kits/AddRouterDialog"
import {
  PowerSchedules,
  PowerTypes,
  VpnServers,
} from "@/components/constants.js"
import SimsColumn from "@/components/SimsColumn"
import { mapStores } from "pinia"
import { useKitSummaryStore } from "@/stores/kitSummary"
import { useRouterStore } from "@/stores/router"
import SmsHistoryDialog from "@/components/sims/SmsHistoryDialog.vue"
import { AdminCamera, Router, Sim } from "@evercam/shared/types"
import vpnServers from "@evercam/shared/constants/vpnServers"
import Autocomplete from "@evercam/shared/components/Autocomplete"
import CameraSummaryRouter from "@/components/cameraTabs/summary/CameraSummaryRouter.vue"

export default Vue.extend({
  components: {
    CameraSummaryRouter,
    SmsHistoryDialog,
    SummaryElement,
    AddRouterDialog,
    SimsColumn,
    Autocomplete,
  },
  data() {
    return {
      editSims: false,
      isHovered: false,
      sims: [],
      selectedSim: null as Sim | null,
      showSmsHistoryDialog: false,
      addRouterDialog: false,
      routerTypes: [],
      cameras: [],
      vpnServers: vpnServers,
      suggestedRouter: null as Router | null,
      showSuggestedRouterDialog: false,
      isLinkingRouter: false,
      vpnServer: {
        key: "vpnServer",
        label: "VPN Server",
        value: "",
        type: "SINGLE_SELECT",
        dataset: VpnServers,
      },
      httpPort: {
        key: "routerHttpPort",
        label: "HTTP Port",
        value: null,
        type: "TEXT_FIELD",
      },
      powerSchedule: {
        key: "powerSchedule",
        label: "Power Schedule",
        value: "",
        type: "SINGLE_SELECT",
        dataset: PowerSchedules,
      },
      routerType: {
        key: "routerType",
        label: "Router Type",
        value: "",
        type: "SINGLE_SELECT",
        dataset: [],
      },
      routerUserId: {
        key: "routerUserId",
        label: "Router User ID",
        value: "",
        type: "TEXT_FIELD",
      },
      routerPassword: {
        key: "routerPassword",
        label: "Router Password",
        value: "",
        type: "TEXT_FIELD",
      },
      powerType: {
        key: "powerType",
        label: "Power Type",
        value: "",
        type: "SINGLE_SELECT",
        dataset: PowerTypes,
      },
    }
  },
  computed: {
    ...mapStores(useKitSummaryStore, useRouterStore),
    hasRouter(): boolean {
      return !!this.kitSummaryStore.router.id
    },
    cameraWithExistingRouter(): AdminCamera | null {
      return (
        (this.kitSummaryStore.cameras.find(
          (c) => !!c.routerId
        ) as unknown as AdminCamera) || null
      )
    },
    suggestedRouterId(): number | null {
      return this.cameraWithExistingRouter?.routerId || null
    },
    imageUrl(): string {
      let routerModel = ""

      switch (this.kitSummaryStore.router?.routerType) {
        case "RUTX11":
          routerModel = "rutx11"
          break
        case "Teltonika RUTX14":
          routerModel = "rutx14"
          break
        case "Teltonika 955":
          routerModel = "rut955"
          break
        case "Teltonika 956":
          routerModel = "rut956"
          break
        default:
          routerModel = "rut955"
          break
      }

      return require(`~/assets/img/router/${routerModel}.gif`)
    },
    getVpnServerUrl(): string {
      if (!this.vpnServer.value) return ""

      let vpnServer = this.vpnServers.filter(
        (el) => el.name === this.vpnServer.value
      )[0]

      return `https://${vpnServer.value}:${this.httpPort.value}`
    },
  },
  watch: {
    hasRouter: {
      immediate: true,
      deep: true,
      async handler() {
        this.bindRouter()
      },
    },
    suggestedRouterId: {
      immediate: true,
      handler() {
        if (!this.hasRouter && this.suggestedRouterId) {
          this.fetchSuggestedRouter()
        }
      },
    },
  },
  async created() {
    this.bindRouter()
    await this.getRouterTypes()
  },
  methods: {
    bindRouter() {
      this.routerType.value = this.kitSummaryStore.router.routerType
      this.powerType.value = this.kitSummaryStore.router.powerType
      this.powerSchedule.value = this.kitSummaryStore.router.powerSchedule
      this.routerPassword.value = this.kitSummaryStore.router.routerPassword
      this.routerUserId.value = this.kitSummaryStore.router.routerUserId
      this.vpnServer.value = this.kitSummaryStore.router.vpnServer
      this.httpPort.value = this.kitSummaryStore.router.routerHttpPort
      this.sims = this.kitSummaryStore.router.sims
    },
    async getRouterTypes() {
      try {
        this.routerTypes = await AdminApi.routers.getRouterTypes()
        let types = []
        this.routerTypes.forEach((element) => {
          types.push({ value: element, name: element })
        })
        this.routerType.dataset = types
      } catch (error) {
        this.$notifications.error({
          text: "Could not load router types!",
          error,
        })
      }
    },
    async saveRouter() {
      let params = {
        serialNumber: this.kitSummaryStore.router.serialNumber,
        routerType: this.routerType.value,
        powerType: this.powerType.value,
        powerSchedule: this.powerSchedule.value,
        routerPassword: this.routerPassword.value,
        routerUserId: this.routerUserId.value,
        vpnServer: this.vpnServer.value,
        routerHttpPort: this.httpPort.value,
        netbirdUrl: this.kitSummaryStore.router.netbirdUrl,
      }
      this.kitSummaryStore.updateRouter(params)
    },
    async fetchSuggestedRouter() {
      try {
        this.suggestedRouter = (await AdminApi.routers.getRouter(
          this.suggestedRouterId
        )) as unknown as Router
      } catch (e) {
        console.log(
          `Failed to fetch suggested router ${this.suggestedRouterId}`,
          e
        )
      }
    },
    async linkSuggestedRouter() {
      try {
        this.isLinkingRouter = true
        await AdminApi.routers.updateRouter(this.suggestedRouterId, {
          kitId: this.kitSummaryStore.kit.id,
        })
        this.kitSummaryStore.router = {
          ...this.suggestedRouter,
        }
        this.$notifications.success(
          `Router ${this.suggestedRouterId} linked to kit ${this.kitSummaryStore.kit.serial} successfully`
        )
      } catch (e) {
        this.$notifications.error({
          text: "Failed to link router to Kit!",
          error: e,
        })
      } finally {
        this.isLinkingRouter = false
        this.showSuggestedRouterDialog = false
      }
    },
    addRouter() {
      this.addRouterDialog = true
    },
    openSmsDialog(sim: Sim) {
      this.selectedSim = sim
      this.showSmsHistoryDialog = true
    },
    clickEditSim() {
      if (this.editSims) return
      this.editSims = true
    },
    saveSims() {
      let old_sims = this.kitSummaryStore.router.sims
      let result = old_sims.filter((sim1) =>
        this.sims.every((sim2) => sim2.id != sim1.id)
      )

      let result2 = this.sims.filter((sim1) =>
        old_sims.every((sim2) => sim2.id != sim1.id)
      )

      this.editSims = false
      this.isHovered = false
      if (result.length == 0 && result2.length == 0) return

      this.kitSummaryStore.updateRouter({ sims: this.sims })
    },
  },
})
</script>

<style>
.box-details {
  min-height: 280px;
}

.text--add-kit-router {
  height: auto !important;
  font-size: 0.8rem;
  padding: 0 !important;
}
.suggested-router-dialog .summary-element__label {
  font-weight: 600 !important;
}
</style>
