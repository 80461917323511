<template>
  <ECard
    title="KIT"
    :image="`${require('~/assets/img/kit.gif')}`"
    blur-background
    :image-translation="$vuetify.breakpoint.smAndDown ? 10 : 20"
    :image-size="75"
    :image-padding="5"
    class="kit"
    v-bind="$attrs"
  >
    <template #title>
      {{ kitSummaryStore.kit.serial }}
      <CopyToClipboardBtn
        :text="kitSummaryStore.kit.serial"
        color="primary"
        icon
      />
      <OpenInNewWindowButton
        :target-link="grafanaKitUrl"
        hint="Open In Grafana"
      />
    </template>
    <div class="e-flex e-flex-wrap e-gap-3">
      <div class="kit__details e-flex e-gap-3">
        <div>
          <!-- STATUS -->
          <SummaryElement
            v-model="status"
            :has-default-save-callback="false"
            @save="updateKit({ status: status.value })"
          >
            <template #value-text>
              <KitStatusChip :status="kitSummaryStore.kit.status" />
            </template>
          </SummaryElement>

          <!-- NAME -->
          <SummaryElement
            v-model="name"
            :has-default-save-callback="false"
            @save="updateKit({ name: name.value })"
          />

          <!-- POWER TYPE -->
          <SummaryElement
            v-model="powerType"
            :has-default-save-callback="false"
            @save="updateKit({ powerType: powerType.value })"
          />
        </div>

        <div>
          <!-- CREATED AT -->
          <SummaryElement read-only label="Created At">
            {{ formatDate(kitSummaryStore.kit.createdAt) }}
          </SummaryElement>

          <!-- COUNTRY -->
          <SummaryElement read-only label="Country">
            {{ kitSummaryStore.kit.countryName }}
            <FlagIcon :code="countryCode" />
          </SummaryElement>

          <!-- REGION -->
          <SummaryElement read-only label="Region">
            {{ kitSummaryStore.kit.countryRegion?.toUpperCase() }}
          </SummaryElement>
        </div>

        <div>
          <!-- PROJECT NAME -->
          <SummaryElement read-only label="Project Name">
            <span
              class="cursor-pointer primary--text"
              @click="openProjectDialog(kitSummaryStore.project.exid)"
            >
              {{ kitSummaryStore.project.name }}
            </span>
            <OpenInZohoButton
              :zoho-id="kitSummaryStore.project.zohoId"
              type="project"
            />
          </SummaryElement>

          <!-- PROJECT EXID -->
          <SummaryElement
            read-only
            label="Project Exid"
            is-copyable
            :value="kitSummaryStore.project.exid"
          />

          <!-- PROJECT TIMEZONE -->
          <SummaryElement read-only label="Project Timezone">
            {{ kitSummaryStore.project.timezone }}
          </SummaryElement>
        </div>

        <div>
          <!-- PROJECT STATUS -->
          <SummaryElement read-only label="Project Status">
            {{
              kitSummaryStore.project.status
                ? kitSummaryStore.project.status
                : "n/a"
            }}
          </SummaryElement>

          <!-- PROJECT START DATE -->
          <SummaryElement read-only label="Project Start Date">
            {{ formatDate(kitSummaryStore.project.startedAt, "DD MMM YYYY") }}
          </SummaryElement>
        </div>
      </div>

      <KitNvrDetails
        class="kit__component"
        :grafana-metrics="grafanaMetrics"
        :ex-nvr-metrics="exNvrMetrics"
      />
      <KitRouterDetails class="kit__component" />
    </div>
  </ECard>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { mapStores } from "pinia"
import SummaryElement from "@/components/cameraTabs/summary/SummaryElement"
import { useKitSummaryStore } from "@/stores/kitSummary"
import CopyToClipboardBtn from "@evercam/shared/components/CopyToClipboardBtn"
import countries from "@evercam/shared/constants/countries"
import KitNvrDetails from "@/components/kits/KitNvrDetails"
import KitRouterDetails from "@/components/kits/KitRouterDetails"
import OpenInZohoButton from "@/components/OpenInZohoButton.vue"
import KitStatusChip from "@/components/kits/KitStatusChip.vue"
import OpenInNewWindowButton from "@/components/OpenInNewWindowButton"
import FlagIcon from "@evercam/shared/components/FlagIcon"
import {
  ExNvrMetric,
  ExNvrMetricId,
  GrafanaMetricId,
  GrafanaMetricResponse,
  Kit,
  KitStatus,
  PatchPayload,
  ProjectExid,
} from "@evercam/shared/types"
import { useBreadcrumbStore } from "@/stores/breadcrumb"
import { useProjectStore } from "@/stores/projects"

import { KitStatuses, PowerTypes } from "@/components/constants"

export default Vue.extend({
  components: {
    KitStatusChip,
    OpenInZohoButton,
    SummaryElement,
    CopyToClipboardBtn,
    KitNvrDetails,
    KitRouterDetails,
    OpenInNewWindowButton,
    FlagIcon,
  },
  props: {
    grafanaMetrics: {
      type: Object as PropType<Record<
        GrafanaMetricId,
        GrafanaMetricResponse
      > | null>,
      default: null,
    },
    exNvrMetrics: {
      type: Object as PropType<Record<ExNvrMetricId, ExNvrMetric> | null>,
      default: null,
    },
  },
  data() {
    return {
      status: {
        key: "status",
        label: "Status",
        value: "",
        type: "SINGLE_SELECT",
        dataset: KitStatuses,
      },
      name: {
        key: "name",
        label: "Name",
        value: "",
        type: "TEXT_FIELD",
      },
      powerType: {
        key: "powerType",
        label: "Power Type",
        value: "",
        type: "SINGLE_SELECT",
        dataset: PowerTypes,
      },
    }
  },
  computed: {
    ...mapStores(useKitSummaryStore, useProjectStore),
    countries(): Record<string, string>[] {
      return countries
    },
    countryCode(): string {
      return this.countries.find(
        (item) => item.name === this.kitSummaryStore.kit.countryName
      )?.value
    },
    projectExid(): ProjectExid {
      return this.kitSummaryStore.project.exid
        ? this.kitSummaryStore.project.exid
        : ""
    },
    grafanaKitUrl() {
      return this.$config.public.grafanaKitUrl.replace(
        "{KIT_SERIAL}",
        encodeURIComponent(this.kitSummaryStore.kit.serial)
      )
    },
    isNewStatus() {
      return !this.status.value || this.status.value === KitStatus.New
    },
  },
  watch: {
    isNewStatus: {
      handler(isNewStatus) {
        this.status.dataset = isNewStatus
          ? KitStatuses
          : KitStatuses.filter(({ value }) => value !== KitStatus.New)
      },
      immediate: true,
    },
  },
  created() {
    this.bindKit()
  },
  methods: {
    bindKit() {
      this.status.value = this.kitSummaryStore.kit.status
      this.name.value = this.kitSummaryStore.kit.name
      this.powerType.value = this.kitSummaryStore.kit.powerType
    },
    async updateKit(change: PatchPayload<Kit>) {
      await this.kitSummaryStore.updateKit(change)
      if (change?.name) {
        this.updateBreadCrumbs()
      }
    },
    updateBreadCrumbs() {
      const breadcrumbsStore = useBreadcrumbStore()
      this.$set(
        breadcrumbsStore.breadcrumbs,
        breadcrumbsStore.breadcrumbs.length - 1,
        {
          name: `${this.kitSummaryStore.kit.name} - (${this.kitSummaryStore.kit.serial})`,
        }
      )
    },
    openProjectDialog(projectExid) {
      this.projectStore.openDialog(projectExid)
    },
  },
})
</script>

<style lang="scss">
.kit {
  &__details {
    flex-direction: row;
    @media (min-width: 1390px) {
      flex-direction: column;
      gap: 0;
    }
    @media (max-width: 960px) {
      flex-wrap: wrap;
      gap: 0 0.75rem;
    }
  }
  &__component {
    margin: 0.5rem;
    margin-top: -1.5rem;
    flex-basis: min-content;
    flex-grow: 1;
    @media (max-width: 1389px) {
      margin-top: 1.5rem;
      margin-left: 1rem;
      width: 45%;
    }
    @media (max-width: 960px) {
      width: 100%;
    }
  }
}
</style>
