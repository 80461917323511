<template>
  <v-dialog
    v-model="dialog"
    max-width="450px"
    persistent
    @keydown.esc="dialog = false"
  >
    <v-card>
      <v-card-title>
        <span>Add NVR</span>
      </v-card-title>
      <v-divider />
      <ValidationObserver v-slot="{ invalid }" ref="nvrForm">
        <v-card-text>
          <!-- NVR Serial Number -->
          <v-text-field
            v-model="serial"
            class="caption"
            label="Serial"
            placeholder="Serial"
            dense
            outlined
          />

          <!-- NVR Model -->
          <ValidationProvider v-slot="{ errors }" name="Model" rules="required">
            <v-select
              v-model="model"
              :items="models"
              item-value="exid"
              item-text="name"
              class="caption"
              label="Nvr Type"
              placeholder="Nvr Type"
              :error-messages="errors"
              dense
              outlined
              clearable
            >
              <template #item="{ item }">
                <span class="caption"> {{ item.name }} </span>
              </template>
            </v-select>
          </ValidationProvider>

          <!-- NVR Device Type -->
          <v-select
            v-model="deviceType"
            :items="deviceTypes"
            item-value="value"
            item-text="name"
            class="caption"
            label="SBC Type"
            placeholder="SBC Type"
            dense
            outlined
            clearable
          />

          <!-- NVR HTTP Url -->
          <ValidationProvider
            v-slot="{ errors }"
            vid="nvr_host"
            name="HTTP Url"
            rules="required"
          >
            <v-text-field
              v-model="httpUrl"
              class="caption"
              label="HTTP Url"
              placeholder="HTTP Url"
              :error-messages="errors"
              disabled
              required
              dense
              outlined
            />
          </ValidationProvider>

          <!-- NVR RTSP Url -->
          <v-text-field
            v-model="rtspUrl"
            class="caption"
            label="RTSP Url"
            placeholder="RTSP Url"
            dense
            outlined
          />

          <!-- NVR Username -->
          <ValidationProvider
            v-slot="{ errors }"
            name="Username"
            rules="required"
          >
            <v-text-field
              v-model="username"
              :error-messages="errors"
              class="caption"
              label="Username"
              autocomplete="new-username"
              placeholder="Username"
              required
              dense
              outlined
            />
          </ValidationProvider>

          <!-- NVR Password -->
          <ValidationProvider
            v-slot="{ errors }"
            name="Password"
            rules="required"
          >
            <v-text-field
              v-model="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              :error-messages="errors"
              class="caption"
              label="Password"
              autocomplete="new-password"
              placeholder="Password"
              required
              dense
              outlined
              @click:append="showPassword = !showPassword"
            >
            </v-text-field>
          </ValidationProvider>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="dialog = false"> Cancel </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="invalid"
            :loading="isLoading"
            @click="createNvr"
          >
            Save
          </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import vendorModels from "@evercam/shared/constants/vendorModels"
import { NvrDeviceTypes } from "@/components/constants"
import { useKitSummaryStore } from "@/stores/kitSummary"
import { mapStores } from "pinia"
import { NvrModel } from "@evercam/shared/types"
import { AdminApi } from "@evercam/api/api/adminApi"

export default {
  name: "AddNvrDialog",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      serial: null,
      model: NvrModel.ExNvr,
      deviceType: null,
      username: null,
      password: null,
      httpUrl: null,
      rtspUrl: null,
      showPassword: null,
      models: vendorModels.NVRMODELS,
      deviceTypes: NvrDeviceTypes,
      isLoading: false,
    }
  },
  computed: {
    ...mapStores(useKitSummaryStore),
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
  },
  mounted() {
    this.initNvrFields()
  },
  methods: {
    initNvrFields() {
      const region = this.kitSummaryStore.kit.countryRegion.replace("na", "us")
      const kitSerial = this.kitSummaryStore.kit.serial.toLowerCase()
      this.httpUrl = `https://${kitSerial}.${region}-vpn.evercam.io`
    },
    async createNvr() {
      try {
        this.isLoading = true
        const payload = {
          serial: this.serial,
          model: this.model,
          deviceType: this.deviceType,
          username: this.username,
          password: this.password,
          httpUrl: this.httpUrl,
          rtspUrl: this.rtspUrl,
          kitId: this.kitSummaryStore.kit.id,
        }

        await AdminApi.nvrs.createNvr(payload)
        this.$notifications.success("Nvr created successfully!")
        this.$emit("created")
        this.dialog = false
      } catch (error) {
        this.$notifications.error({ text: "Failed to create nvr!", error })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
