<template>
  <ECard
    v-if="hasData"
    class="chart-container kit-metric"
    :class="{ 'e-mx-auto e-overflow-hidden': isTable }"
    :width="width"
    :title="metricName"
  >
    <template #title>
      <div class="d-flex justify-center e-pt-3">
        <span class="e-text-sm pl-1">{{ metricName }}</span>
        <div class="e-absolute e-right-4">
          <OpenInNewWindowButton
            :target-link="grafanaUrl"
            hint="Open in Grafana"
            class="ml-auto mr-2"
          />
        </div>
      </div>
    </template>

    <KitMetricGraphChart
      v-if="isGraphChart"
      :metric="metric"
      :convert-to-bytes="convertToBytes"
      :convert-to-bits-per-second="convertToBitsPerSecond"
      :stacking="stackingBehavior"
      :percentage="isPercentage"
      :temperature="isTemperature"
      :default-toolip="useDefaultTooltip"
    />
    <KitMetricGaugeChart v-else-if="isGaugeChart" :metric="metric" />
    <KitMetricTable v-else-if="isTable" :metric="metric" />
  </ECard>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import KitMetricTable from "@/components/kits/KitMetricTable.vue"
import KitMetricGraphChart from "@/components/kits/KitMetricGraphChart.vue"
import KitMetricGaugeChart from "@/components/kits/KitMetricGaugeChart.vue"
import OpenInNewWindowButton from "@/components/OpenInNewWindowButton.vue"
import { KitMetricNames } from "@evercam/shared/constants/kits"
import {
  Kit,
  GrafanaMetricId,
  GrafanaMetricResponse,
  GrafanaChartType,
  HighchartsStackingBehavior,
  ExNvrMetric,
} from "@evercam/shared/types"

export default Vue.extend({
  name: "KitMetric",
  components: {
    KitMetricGaugeChart,
    KitMetricGraphChart,
    KitMetricTable,
    OpenInNewWindowButton,
  },
  props: {
    kit: {
      type: Object as PropType<Kit>,
      required: true,
    },
    metric: {
      type: [Object, Number] as PropType<GrafanaMetricResponse | ExNvrMetric>,
      required: true,
    },
    metricId: {
      type: String as PropType<GrafanaMetricId>,
      required: true,
    },
  },
  computed: {
    isGraphChart(): boolean {
      return [GrafanaChartType.Graph, GrafanaChartType.TimeSeries].includes(
        this.metric?.type
      )
    },
    isGaugeChart(): boolean {
      return this.metric?.type === GrafanaChartType.Gauge
    },
    isTable(): boolean {
      return this.metric?.type === GrafanaChartType.Table
    },
    width(): string {
      if (this.isTable) {
        return "fit-content"
      } else if (this.isGaugeChart) {
        return "20%"
      } else if (this.$vuetify.breakpoint.lgAndUp) {
        return "30%"
      } else if (this.$vuetify.breakpoint.mdOnly) {
        return "45%"
      } else {
        return "100%"
      }
    },
    metricName(): string {
      return KitMetricNames[this.metricId]
    },
    grafanaUrl(): string {
      return this.$config.public.grafanaDashboardUrl.replace(
        "{KIT_SERIAL}",
        this.kit.serial
      )
    },
    isPercentage(): boolean {
      return [
        GrafanaMetricId.SbcCpuStats,
        GrafanaMetricId.StorageSpaceUsed,
      ].includes(this.metricId)
    },
    useDefaultTooltip(): boolean {
      return [GrafanaMetricId.SbcCpuStats].includes(this.metricId)
    },
    isTemperature(): boolean {
      return [
        GrafanaMetricId.SbcTemperatureStats,
        GrafanaMetricId.RouterTemperature,
      ].includes(this.metricId)
    },
    convertToBytes(): boolean {
      return [GrafanaMetricId.SbcRamStats].includes(this.metricId)
    },
    convertToBitsPerSecond(): boolean {
      return [GrafanaMetricId.SbcNetworkStats].includes(this.metricId)
    },
    stackingBehavior(): string | false {
      if ([GrafanaMetricId.SbcRamStats].includes(this.metricId)) {
        return HighchartsStackingBehavior.Normal
      } else if ([GrafanaMetricId.SbcCpuStats].includes(this.metricId)) {
        return HighchartsStackingBehavior.Percent
      } else {
        return false
      }
    },
    hasData(): boolean {
      if (!Number.isNaN(parseInt(this.metric.value))) {
        return true
      }

      if (!this.metric?.results) {
        return false
      }

      const resultEntries = Object.entries(this.metric.results)

      if (this.isTable) {
        const sizeData = resultEntries.find(([key]) => key === "A")?.[1]

        return !!sizeData?.frames?.[0]?.schema?.fields?.[1]?.labels
      }

      return resultEntries.some(([, value]) =>
        value?.frames?.some(
          (frame) =>
            frame?.schema?.fields?.length > 0 && frame?.data?.values?.length > 0
        )
      )
    },
  },
})
</script>

<style lang="scss">
.chart-container {
  padding: 0 !important;
}
.kit-metric {
  max-width: 100%;
}
</style>
